@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link {
  @apply px-4 pb-1 border-b-2 text-center transition-all font-semibold cursor-pointer;
  letter-spacing: 2px;
}


.image-card {
  width: 300px;
  height: 400px;
  @apply shadow-2xl shadow-blue-200 rounded-xl cursor-pointer
}

.image-card>img {
  height: 400px;
  width: 300px;
  @apply rounded-xl object-cover;
}

.pagination-link {
  @apply cursor-pointer border-2 border-blue-400 rounded-full flex justify-center items-center
}

.action-btn {
  @apply p-2 px-12 border-2 border-transparent bg-blue-800 rounded-xl transition-all text-white font-bold shadow-blue-300 shadow-lg outline-none;
}

.alert-enter {
  display: none;
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  display: block;
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}